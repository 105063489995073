/******************* IPAddress Component *******************************/
import {
    React,
    useReactToPrint,
    useRef,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
} from "../../common/Imports";

const IPAddress = ({ data }) => {
    // Create a ref for the table container
    const tableRef = useRef();

    // Function to handle printing
    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
        pageStyle: `
       @page {
         size: A4;
         margin: 2cm;
       }
     `,
    });

    // Ensure data is an array with at least one object
    if (!Array.isArray(data) || data.length === 0) {
        return null; // Or display a message indicating no data
    }

    const ipAddressData = data[0]; // Access the object within the array

    return (
        <div className="container">
            <div className="table_container">
                <h1>IP Address Details</h1>
                {/* Button for printing */}
                <Button
                    variant="contained"
                    onClick={handlePrint}
                    style={{
                        backgroundColor: "#1ad5b3",
                        color: "#fff",
                        fontWeight: "600",
                        borderRadius: "20px",
                        marginBottom: "20px",
                    }}
                >
                    Print
                </Button>
                {/* Table for displaying imei details */}
                <div className="table" ref={tableRef}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        IP Address
                                    </TableCell>
                                    <TableCell>
                                        {ipAddressData.IPAddress}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Country Code
                                    </TableCell>
                                    <TableCell>
                                        {ipAddressData.Countrycode}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Country
                                    </TableCell>
                                    <TableCell>
                                        {ipAddressData.Country}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Region
                                    </TableCell>
                                    <TableCell>
                                        {ipAddressData.Region}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        City
                                    </TableCell>
                                    <TableCell>{ipAddressData.City}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Latitude
                                    </TableCell>
                                    <TableCell>
                                        {ipAddressData.Latitude}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Longitude
                                    </TableCell>
                                    <TableCell>
                                        {ipAddressData.Longitude}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Zip Code
                                    </TableCell>
                                    <TableCell>
                                        {ipAddressData.Zipcode}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Time Zone
                                    </TableCell>
                                    <TableCell>
                                        {ipAddressData.Timezone}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        ISP
                                    </TableCell>
                                    <TableCell>{ipAddressData.ISP}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Domain
                                    </TableCell>
                                    <TableCell>
                                        {ipAddressData.Domain}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Net Speed
                                    </TableCell>
                                    <TableCell>
                                        {ipAddressData.Netspeed}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    );
};
export default IPAddress;
