/******************* SearchResults Component *******************************/
import { React, lazy, Suspense } from "./Imports.jsx";

// Lazy load components
const NumberLookup = lazy(() => import("../Main/components/NumberLookup.jsx"));
const IFSCLookup = lazy(() => import("../Main/components/IFSCLookup.jsx"));
const MICRLookup = lazy(() => import("../Main/components/MICRLookup.jsx"));
const VehicleInfo = lazy(() => import("../Main/components/VehicleInfo.jsx"));
const IPAddress = lazy(() => import("../Main/components/IPAddress.jsx"));
const ASNLookup = lazy(() => import("../Main/components/ASNLookup.jsx"));
const Email = lazy(() => import("../Main/components/Email.jsx"));
const IMEILookup = lazy(() => import("../Main/components/IMEILookup.jsx"));
const CompanyLookup = lazy(() => import("../Main/components/CompanyLookup.jsx"));
const DNSLookup = lazy(() => import("../Main/components/DNSLookup.jsx"));
const GoogleSearch = lazy(() => import("../Main/components/GoogleSearch.jsx"));

const componentMap = {
  "Number Lookup": NumberLookup,
  "IFSC Code": IFSCLookup,
  "MICR Code": MICRLookup,
  "Vehicle Information": VehicleInfo,
  "IP Address": IPAddress,
  "ASN Number": ASNLookup,
  "Email Status": Email,
  "IMEI Number": IMEILookup,
  "Company Lookup": CompanyLookup,
  "DNS Lookup": DNSLookup,
  "Google Search": GoogleSearch,
};

const SearchResults = ({ searchResults }) => {
  if (!searchResults) {
    return <h1>No results found</h1>;
  }

  return (
    <div>
      {searchResults.map((result, index) => {
        const Component = componentMap[result.title];
        return (
          <Suspense fallback={<div>Loading...</div>} key={index}>
            <Component data={result.data} />
          </Suspense>
        );
      })}
    </div>
  );
};

export default SearchResults;
