/******************* SearchBar Component *******************************/
import React from "./Imports";

const SearchBar = ({ value, onChange, onSearch, loading }) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  return (
    <div className="container">
      <div className="search_container">
        <input
          type="text"
          value={value}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          placeholder="Enter Search Term..."
          className="search_bar"
        />
        <button className="main_button" onClick={onSearch} disabled={loading}>
          Search
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
