/******************* Navbar Component *******************************/
import React from "./Imports";

const Navbar = () => {
  return (
    <nav className="navbar">
      <h1>Welcome</h1>
    </nav>
  );
};

export default Navbar;
