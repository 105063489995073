/************************** execution starts for this file ****************************/
import { React, Router } from "./Imports";

const App = () => {
    return (
        <>
            <Router />
        </>
    );
};

export default App;
