/******************* MainPage  *******************************/
import {
  React,
  axios,
  swal,
  useEffect,
  useState,
  useRef,
  useCallback,
  ButtonCard,
  SearchBar,
  Navbar,
  Backdrop,
  CircularProgress,
  apiUrlMap,
  buttonTitles,
  SearchResults,
} from "../common/Imports";

const Main = () => {
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const resultsRef = useRef(null);

  const handleSearchChange = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  const handleSearchClick = useCallback(async () => {
    setLoading(true);
    try {
      const responses = [];
      const errorMessages = [];
      let apiUrl, queryParams;

      if (!searchQuery.trim()) {
        throw new Error("Please enter search query");
      }

      // Move this variable declaration outside the loop
      queryParams = {
        contactNo: "",
        ifscCode: "",
        micrCode: "",
        numberPlate: "",
        asnNumber: "",
        emailID: "",
        companyName: "",
        domainName: "",
        searchParam: "",
        imeiNumber: "",
      };

      // Call backend API for regex pattern matching
      const regexResponse = await axios.post("/api/user/validate", {
        query: searchQuery,
      });

      // If regex pattern matched, fetch data for each matched title
      if (regexResponse.data.length > 0) {
        for (const title of regexResponse.data) {
          if (title === "IP Address") {
            // If the matched title is "IP Address", make a POST request to the IP Address Lookup API
            apiUrl = apiUrlMap[title];
            const ipAddressWithoutDots = searchQuery.replace(/\./g, ""); // Remove dots from the IP address
            const ipResponse = await axios.post(apiUrl, {
              ipAddress: ipAddressWithoutDots,
            });
            responses.push({ title: title, data: ipResponse.data });
            errorMessages.push(title);
          } else {
            // For other matched titles, make a GET request to the corresponding API endpoint
            apiUrl = apiUrlMap[title];
            queryParams.contactNo =
              title === "Number Lookup" ? searchQuery : "";
            queryParams.ifscCode = title === "IFSC Code" ? searchQuery : "";
            queryParams.micrCode = title === "MICR Code" ? searchQuery : "";
            queryParams.numberPlate =
              title === "Vehicle Information" ? searchQuery : "";
            queryParams.asnNumber = title === "ASN Number" ? searchQuery : "";
            queryParams.emailID = title === "Email Status" ? searchQuery : "";
            queryParams.companyName =
              title === "Company Lookup" ? searchQuery : "";
            queryParams.domainName = title === "DNS Lookup" ? searchQuery : "";
            queryParams.searchParam =
              title === "Google Search" ? searchQuery : "";
            queryParams.imeiNumber = title === "IMEI Number" ? searchQuery : "";

            const getResponse = await axios.get(apiUrl, {
              params: queryParams,
            });
            if (getResponse.data) {
              responses.push({
                title: title,
                data: getResponse.data,
              });
              errorMessages.push(title);
            }
          }
        }
      }

      if (responses.length > 0) {
        setSearchResults(responses);
        swal({
          title: "Success",
          text: `Data found: ${errorMessages.join(", ")}`,
          icon: "success",
        });
      } else {
        setSearchResults(null);
        swal({
          title: "Error",
          text: `No record found`,
          icon: "error",
        });
      }
    } catch (error) {
      error.response
        ? swal({
            title: `${error.response.status}`,
            text: `${error.response.data}`,
            icon: "error",
          })
        : swal({
            title: `Error`,
            text: `${error}`,
            icon: "error",
          });
    } finally {
      setLoading(false);
    }
  }, [searchQuery]);

  const handleCardButton = useCallback(
    async (buttonText) => {
      setLoading(true);
      try {
        const responses = [];

        if (!buttonText) {
          throw new Error("No button text provided");
        }

        if (!searchQuery) {
          throw new Error("Please enter search query");
        }

        // Call backend API to validate search query
        const validateResponse = await axios.post("/api/user/validate", {
          query: searchQuery,
        });

        // Check if the search query matches the regex pattern for the selected button's type
        if (!validateResponse.data.includes(buttonText)) {
          throw new Error(
            `Please enter a valid search query for ${buttonText}`
          );
        }

        const apiUrl = apiUrlMap[buttonText];
        let formattedSearchQuery = searchQuery;

        if (buttonText === "IP Address") {
          formattedSearchQuery = searchQuery.replace(/\./g, "");
        }

        const queryParams = {
          contactNo: buttonText === "Number Lookup" ? searchQuery : "",
          searchParam: buttonText === "Google Search" ? searchQuery : "",
          ifscCode: buttonText === "IFSC Code" ? searchQuery : "",
          micrCode: buttonText === "MICR Code" ? searchQuery : "",
          numberPlate: buttonText === "Vehicle Information" ? searchQuery : "",
          asnNumber: buttonText === "ASN Number" ? searchQuery : "",
          emailID: buttonText === "Email Status" ? searchQuery : "",
          companyName: buttonText === "Company Lookup" ? searchQuery : "",
          domainName: buttonText === "DNS Lookup" ? searchQuery : "",
          imeiNumber: buttonText === "IMEI Number" ? searchQuery : "",
        };

        Object.keys(queryParams).forEach(
          (key) => queryParams[key] === "" && delete queryParams[key]
        );

        let url = apiUrl;

        // Constructing URL with query parameters
        if (Object.keys(queryParams).length > 0) {
          const queryString = new URLSearchParams(queryParams).toString();
          url += `?${queryString}`;
        }

        let response;

        if (buttonText === "IP Address") {
          // For IP Address, use POST method
          response = await axios.post(url, {
            ipAddress: formattedSearchQuery,
          });
        } else {
          // For other types, use GET method
          response = await axios.get(url);
        }

        responses.push({ title: buttonText, data: response.data });

        setSearchResults(responses);
        swal({
          title: "Success",
          text: `Data found in ${buttonText}`,
          icon: "success",
        });
      } catch (error) {
        error.response
          ? swal({
              title: `${error.response.status}`,
              text: `${error.response.data}`,
              icon: "error",
            })
          : swal({
              title: `Error`,
              text: `${error}`,
              icon: "error",
            });
      } finally {
        setLoading(false);
      }
    },
    [searchQuery]
  );

  useEffect(() => {
    if (searchResults.length > 0 && resultsRef.current) {
      resultsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [searchResults]);

  return (
    <>
      <Navbar />
      <SearchBar
        value={searchQuery}
        onChange={handleSearchChange}
        onSearch={handleSearchClick}
        loading={loading}
      />
      <ButtonCard
        buttonTitles={buttonTitles}
        handleCardButton={handleCardButton}
      />
      <div ref={resultsRef} />
      <SearchResults searchResults={searchResults} />
      <Backdrop
        sx={{
          color: "#000",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        className="backdrop"
        open={loading}
        invisible
      >
        <CircularProgress color="inherit" size={64} />
      </Backdrop>
    </>
  );
};

export default Main;
