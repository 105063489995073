/************************** execution starts for this file ****************************/
import { React, App, createRoot } from "./views/common/Imports.jsx";

const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
