/************************** this includes all the button names and api url's ****************************/
export const apiUrlMap = {
  "Number Lookup": "/api/user/number-lookup",
  "IFSC Code": "/api/user/ifsc-lookup",
  "MICR Code": "/api/user/micr-lookup",
  "Company Lookup": "/api/user/company-search-data",
  "IP Address": "/api/user/ip-address-lookup",
  "IMEI Number": "/api/user/imei-lookup",
  "Vehicle Information": "/api/user/vehicle-data-lookup",
  "ASN Number": "/api/user/asn-lookup",
  "Google Search": "/api/user/google-search",
  "Email Status": "/api/user/email-breached-data",
  "DNS Lookup": "/api/user/dns-crawl-data",
};

export const buttonTitles = [
  "Number Lookup",
  "IFSC Code",
  "MICR Code",
  "Company Lookup",
  "IP Address",
  "IMEI Number",
  "Vehicle Information",
  "ASN Number",
  "Email Status",
  "DNS Lookup",
  "Google Search",
];
