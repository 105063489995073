/******************* CompanyLookup Component *******************************/
import {
    React,
    useReactToPrint,
    useRef,
    Button,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
} from "../../common/Imports";

const CompanyLookup = ({ data }) => {
    const tableRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
        pageStyle: `
      @page {
        size: A4;
        margin-top: 2cm;
        margin-bottom: 2cm;
        margin-left: initial;
        margin-right: initial;
      }
    `,
    });

    return (
        <div className="container">
            <div className="table_container">
                <h1>Company Details</h1>
                <Button
                    variant="contained"
                    onClick={handlePrint}
                    style={{
                        backgroundColor: "#1ad5b3",
                        color: "#fff",
                        fontWeight: "600",
                        borderRadius: "20px",
                        marginBottom: "20px",
                    }}
                >
                    Print
                </Button>
                <TableContainer
                    className="{styles.table}"
                    component={Paper}
                    ref={tableRef}
                    style={{ maxWidth: "100%" }}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    style={{
                                        wordWrap: "break-word",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Company Name
                                </TableCell>
                                <TableCell
                                    style={{
                                        wordWrap: "break-word",
                                        fontWeight: "bold",
                                    }}
                                >
                                    CIN
                                </TableCell>
                                <TableCell
                                    style={{
                                        wordWrap: "break-word",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Directors
                                </TableCell>
                                <TableCell
                                    style={{
                                        wordWrap: "break-word",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Incorporated Date
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((company, index) => (
                                <TableRow key={index}>
                                    <TableCell>{company.companyName}</TableCell>
                                    <TableCell>{company.CIN}</TableCell>
                                    <TableCell>
                                        {company.directors.length > 0
                                            ? company.directors
                                            : "Not Found"}
                                    </TableCell>
                                    <TableCell>
                                        {company.inCorporatedDate}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default CompanyLookup;
