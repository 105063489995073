/******************* ASNLookup Component *******************************/
import {
    React,
    useReactToPrint,
    useRef,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
} from "../../common/Imports";

const ASNLookup = ({ data }) => {
    const tableRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
        pageStyle: `
      @page {
        size: A4;
        margin: 2cm;
      }
    `,
    });

    return (
        <div className="styles.container">
            <div className="table_container">
                <h1>ASN Details</h1>
                <Button
                    variant="contained"
                    onClick={handlePrint}
                    style={{
                        backgroundColor: "#1ad5b3",
                        color: "#fff",
                        fontWeight: "600",
                        borderRadius: "20px",
                    }}
                >
                    Print
                </Button>
                <div className="table" ref={tableRef}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        ASN (Autonomous System Number)
                                    </TableCell>
                                    <TableCell>{data.ASN}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Organization
                                    </TableCell>
                                    <TableCell>{data.organization}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Name
                                    </TableCell>
                                    <TableCell>{data.name}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Total IPV4 Address
                                    </TableCell>
                                    <TableCell>
                                        {data.totalIPV4Address}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Total IPV4 Prefix
                                    </TableCell>
                                    <TableCell>
                                        {data.totalIPV4Prefix}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Total IPV6 Prefix
                                    </TableCell>
                                    <TableCell>
                                        {data.totalIPV6Prefix}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Total IPV4 Bogon Prefix
                                    </TableCell>
                                    <TableCell>
                                        {data.totalIPV4BogonPrefix}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Total IPV6 Bogon Prefix
                                    </TableCell>
                                    <TableCell>
                                        {data.totalIPV6BogonPrefix}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        IPV4 Space Rank
                                    </TableCell>
                                    <TableCell>{data.IPV4SpaceRank}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Registry
                                    </TableCell>
                                    <TableCell>{data.registery}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Registration Country
                                    </TableCell>
                                    <TableCell>{data.regCountry}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Registration Country Name
                                    </TableCell>
                                    <TableCell>{data.regCountryName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Registration Date
                                    </TableCell>
                                    <TableCell>{data.regDate}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Registration Last Change
                                    </TableCell>
                                    <TableCell>{data.regLastChange}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    );
};

export default ASNLookup;
