/******************* EmailLookup Component *******************************/
import { React, Alert } from "../../common/Imports";

const Email = ({ data }) => {
    // Check if data is undefined or if data.response is not a string
    if (!data || typeof data.response !== "string") {
        // Return null or handle the case when data is not valid
        return null;
    }

    let severity = "info"; // Default severity
    if (data.response === "Email Compromised") {
        severity = "error";
    } else if (data.response === "Email Not Compromised") {
        severity = "success";
    }

    return (
        <div className="table_container">
            <h1>Email Status</h1>
            <Alert severity={severity}>{data.response}</Alert>
        </div>
    );
};

export default Email;
