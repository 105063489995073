/************************** ButtonCard Component ****************************/
import React from "./Imports";

const ButtonCard = ({ buttonTitles, handleCardButton }) => {
  // Ensure buttonTitles is an array and handleCardButton is a function
  if (!Array.isArray(buttonTitles) || typeof handleCardButton !== "function") {
    throw new Error("Invalid props provided to ButtonCard component");
  }

  return (
    <div className="cards_container">
      {buttonTitles.map((buttonText, index) => (
        <div
          key={index}
          className="card"
          onClick={() => handleCardButton(buttonText)}
        >
          <p className="card_title">{buttonText}</p>
        </div>
      ))}
    </div>
  );
};

export default ButtonCard;
