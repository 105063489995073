/******************* DNSLookup Component *******************************/
import {
    React,
    useReactToPrint,
    useRef,
    Button,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
} from "../../common/Imports";

const DNSLookup = ({ data }) => {
    // Create a ref for the table container
    const tableRef = useRef();

    // Function to handle printing
    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
        pageStyle: `
    @page {
      size: A4;
      margin-top: 2cm;
      margin-bottom: 2cm;
      margin-left: initial;
      margin-right: initial;
    }
  `,
    });

    return (
        <div className="container">
            <div className="table_container">
                <h1>DNS Details</h1>
                {/* Button for printing */}
                <Button
                    variant="contained"
                    onClick={handlePrint}
                    style={{
                        backgroundColor: "#1ad5b3",
                        color: "#fff",
                        fontWeight: "600",
                        borderRadius: "20px",
                        marginBottom: "20px",
                    }}
                >
                    Print
                </Button>
                {/* Table for displaying company details */}
                <TableContainer
                    className="table"
                    component={Paper}
                    ref={tableRef}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">
                                    <b>Name</b>
                                </TableCell>
                                <TableCell align="center">
                                    <b>IP Address</b>
                                </TableCell>
                                <TableCell align="center">
                                    <b>Location</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* Mapping over data array to render table rows */}
                            {data.map((dns, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center">
                                        {dns.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {dns.ipAddress}
                                    </TableCell>
                                    <TableCell align="center">
                                        {dns.location.split(",")[0]}{" "}
                                        {/* Displaying only country name */}
                                        <br />
                                        {dns.location.split(",")[1]}{" "}
                                        {/* Displaying rest of the location */}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default DNSLookup;
