/******************* MICRLookup Component *******************************/
import {
    React,
    useRef,
    useReactToPrint,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
} from "../../common/Imports";

const MICRLookup = ({ data }) => {
    // Create a ref for the table container
    const tableRef = useRef();

    // Function to handle printing
    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
        pageStyle: `
      @page {
        size: A4;
        margin: 2cm;
      }
    `,
    });

    return (
        <div className="container">
            <div className="table_container">
                <h1>MICR Details</h1>
                {/* Button for printing */}
                <Button
                    variant="contained"
                    onClick={handlePrint}
                    style={{
                        backgroundColor: "#1ad5b3",
                        color: "#fff",
                        fontWeight: "600",
                        borderRadius: "20px",
                    }}
                >
                    Print
                </Button>
                {/* Table for displaying IFSC details */}
                <div className="table" ref={tableRef}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                {/* Rendering rows with hardcoded keys */}
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        MICR Code
                                    </TableCell>
                                    <TableCell>{data.micrCode}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Bank Name
                                    </TableCell>
                                    <TableCell>{data.bankName}</TableCell>
                                </TableRow>
                                {/* <TableRow>
                  <TableCell component="th" scope="row">
                    Address
                  </TableCell>
                  <TableCell>{data.address}</TableCell>
                </TableRow> */}
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        State
                                    </TableCell>
                                    <TableCell>{data.state}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        District
                                    </TableCell>
                                    <TableCell>{data.district}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Branch
                                    </TableCell>
                                    <TableCell>{data.branch}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    );
};

export default MICRLookup;
