/******************* IMEILookup Component *******************************/
import {
    React,
    useRef,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    useReactToPrint,
} from "../../common/Imports";

const IMEILookup = ({ data }) => {
    console.log(data);
    // Create a ref for the table container
    const tableRef = useRef();

    // Function to handle printing
    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
        pageStyle: `
      @page {
        size: A4;
        margin: 2cm;
      }
    `,
    });

    // // Ensure data is an array with at least one object
    // if (!Array.isArray(data) || data.length === 0) {
    //   return null; // Or display a message indicating no data
    // }

    // const imeiData = data[0]; // Access the object within the array

    return (
        <div className="container">
            <div className="table_container">
                <h1>IMEI Details</h1>
                {/* Button for printing */}
                <Button
                    variant="contained"
                    onClick={handlePrint}
                    style={{
                        backgroundColor: "#1ad5b3",
                        color: "#fff",
                        fontWeight: "600",
                        borderRadius: "20px",
                        marginBottom: "20px",
                    }}
                >
                    Print
                </Button>
                {/* Table for displaying imei details */}
                <div className="styles.table" ref={tableRef}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        IMEI Number
                                    </TableCell>
                                    <TableCell>{data.imeiNumber}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Brand
                                    </TableCell>
                                    <TableCell>{data.brand}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Model
                                    </TableCell>
                                    <TableCell>{data.model}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Code Name
                                    </TableCell>
                                    <TableCell>{data.codeName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Release Year
                                    </TableCell>
                                    <TableCell>{data.releaseYear}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    );
};

export default IMEILookup;
