/******************* NumberLookup Component *******************************/
import {
    React,
    useRef,
    useReactToPrint,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
} from "../../common/Imports";

const NumberLookup = ({ data }) => {
    const tableRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
        pageStyle: `
      @page {
        size: A4;
        margin: 2cm;
      }
    `,
    });

    return (
        <div className="container">
            <div className="table_container">
                <h1>Number Details</h1>
                <Button
                    variant="contained"
                    onClick={handlePrint}
                    style={{
                        backgroundColor: "#1ad5b3",
                        color: "#fff",
                        fontWeight: "600",
                        borderRadius: "20px",
                    }}
                >
                    Print
                </Button>
                <div className="table" ref={tableRef}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Mobile Number
                                    </TableCell>
                                    <TableCell>{data.mobileNumber}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Country
                                    </TableCell>
                                    <TableCell>{data.country}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Country Code
                                    </TableCell>
                                    <TableCell>{data.countryCode}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Phone Carrier
                                    </TableCell>
                                    <TableCell>{data.phoneCarrier}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Area
                                    </TableCell>
                                    <TableCell>{data.areaCode}</TableCell>
                                </TableRow>
                                {/* <TableRow>
                  <TableCell component="th" scope="row">
                    Capital of India
                  </TableCell>
                  <TableCell>{data.capitalOfIndia}</TableCell>
                </TableRow> */}
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Time Zone
                                    </TableCell>
                                    <TableCell>{data.timeZone}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    );
};

export default NumberLookup;

// import React from "react";

// export default function MyTestComponent() {
//   return <div>Test Component</div>;
// }
