/******************* GoogleSearch Results Component *******************************/
import {
    React,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
} from "../../common/Imports";

const GoogleSearch = ({ data }) => {
    return (
        <div className="container">
            <div className="table_container">
                <h1>Google Search Results</h1>
                {data.length === 0 ? ( // Check if data array is empty
                    <p>No records found</p>
                ) : (
                    // Render table if data is not empty
                    <div className="table">
                        <TableContainer component={Paper}>
                            <Table>
                                <TableBody>
                                    {/* Mapping over data array to render table rows */}
                                    {data.map((result, index) => (
                                        <TableRow key={index}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {result.title}
                                            </TableCell>
                                            <TableCell>
                                                <a
                                                    href={result.link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {result.link}
                                                </a>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
            </div>
        </div>
    );
};

export default GoogleSearch;
