/************************ Routing can handle by this file ************************/
import {
    React,
    Routes,
    Route,
    Main,
    BrowserRouter,
} from "../views/common/Imports";

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Main />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
